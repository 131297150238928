import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Spinner, Dropdown } from "react-bootstrap";
import {
  PlusLg,
  CircleFill,
  CheckCircleFill,
  ThreeDotsVertical,
  ArrowClockwise,
  EyeSlash,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { ValueFormatterParams } from "ag-grid-community";

import { Api } from "../../common/api/Api";
import { isoStringToDate, dateToString } from "../../common/Utils";
import ErrorAlert from "../../common/ErrorAlert";
import { hasAdminRights } from "../../common/api/Auth";
import { MAX_ITEMS_WITHOUT_PAGINATION } from "../../../constants";
import { useAppSelector } from "../../../hooks";
import { isApiError as _isApiError } from "../../common/api/Utils";

export default function DocumentsList(): React.JSX.Element {
  const navigate = useNavigate();

  // Reference to the grid, so we can access its API methods.
  const gridRef = React.useRef<AgGridReact<IUploadedDocumentRowData>>(null);
  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [apiError, setApiError] = React.useState<IApiError | undefined>();
  const [rowData, setRowData] = React.useState<IUploadedDocumentRowData[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [enableUpload, setEnableUpload] = React.useState<boolean>(false);
  const [isGridReady, setIsGridReady] = React.useState<boolean>(false);

  const handleDocumentView = (
    currentRowData: IUploadedDocumentRowData,
  ): void => {
    /**
     * View the document from the row data.
     */
    navigate("/dashboard/documents/document", {
      state: { document: currentRowData },
    });
  };

  const dateTimeFormatter = (
    params: ValueFormatterParams,
  ): string | undefined => {
    if (params.value) {
      return dateToString(params.value);
    }
  };

  const actionRenderer = (props: { data: IUploadedDocumentRowData }) => {
    // We only show this element if the user is an admin. We (ab)use the
    // `enableUpload` state element for this
    if (!enableUpload) {
      return <></>;
    }

    // no clue why eslint keeps complaining about "data is missing in props validation"
    // eslint-disable-next-line react/prop-types
    const _currentRowData: IUploadedDocumentRowData = props.data;
    //eslint-disable-next-line react/prop-types
    if (_currentRowData.processStatus !== "failed") {
      return <></>;
    }

    const onDismiss = async () => {
      // eslint-disable-next-line react/prop-types
      const documentId = _currentRowData.id;
      Api.post(`documents/${documentId}/dismiss/`, undefined)
        .then(() => {
          const rowNode = gridRef.current?.api.getRowNode(documentId);
          if (rowNode?.data) {
            gridRef.current?.api.applyTransaction({ remove: [rowNode.data] });
          }
        })
        .catch((error: IApiError) => {
          setApiError(error);
        });
    };

    const onRetry = async () => {
      // eslint-disable-next-line react/prop-types
      const documentId = _currentRowData.id;
      Api.post(`documents/${documentId}/retry/`, undefined)
        .then(() => {
          const rowNode = gridRef.current?.api.getRowNode(documentId);
          rowNode?.setDataValue("processStatus", "submitted");

          // Now we need to redraw the row to get rid of the action buttons
          if (rowNode) {
            gridRef.current?.api.redrawRows({ rowNodes: [rowNode] });
          }

          // However, now for some reason the buttons don't re-appear if the status is failed again.
          // Is that acceptable?
        })
        .catch((error: IApiError) => {
          setApiError(error);
        });
    };

    return (
      <Dropdown className={"position-absolute"} drop={"start"}>
        <Dropdown.Toggle
          title={"Document acties"}
          variant="light"
          size={"sm"}
          id="dropdown-basic"
          className={
            "dropdown-toggle-no-arrow-icon rounded-pill text-dark mb-1 py-1"
          }
        >
          <span className={"d-flex align-items-center py-1"}>
            <ThreeDotsVertical />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className={"mx-1 rounded-3 px-3"}>
          <Dropdown.Item className={"bg-white px-0"}>
            <Button
              title={"Opnieuw proberen"}
              variant="light"
              size={"sm"}
              className={
                "d-flex align-items-center w-100 rounded-3 border-1 border-light-subtle"
              }
              onClick={onRetry}
            >
              <ArrowClockwise className={"me-2"} />
              Opnieuw proberen
            </Button>
          </Dropdown.Item>
          <Dropdown.Item className={"bg-white px-0"}>
            <Button
              title={"Verbergen"}
              variant="light"
              size={"sm"}
              className={
                "d-flex align-items-center w-100 rounded-3 border-1 border-light-subtle"
              }
              onClick={onDismiss}
            >
              <EyeSlash className={"me-2"} />
              Verbergen
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const IsVectorizedComponent = (props: { data: IUploadedDocumentRowData }) => {
    const _currentRowData: IUploadedDocumentRowData = props.data;
    if (_currentRowData?.processStatus === "completed") {
      return (
        <span className={"text-success"} title={"Document is verwerkt"}>
          <CheckCircleFill />
        </span>
      );
    } else if (_currentRowData?.processStatus === "submitted") {
      return (
        <span className={"text-warning"} title={"Document is geüpload"}>
          <CircleFill />
        </span>
      );
    } else if (_currentRowData?.processStatus === "processing") {
      return (
        <Spinner
          title={"Document wordt verwerkt"}
          animation="border"
          variant="secondary"
          style={{ width: "16px", height: "16px" }}
        />
      );
    } else {
      return (
        <span className={"text-danger"} title={"Verwerking mislukt"}>
          <CircleFill />
        </span>
      );
    }
  };

  const columnDefs = [
    {
      field: "name",
      headerName: "Naam",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
        filterPlaceholder: "Zoek in documenten...",
        filterOptions: ["contains"],
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      flex: 6,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onCellClicked: (e: any) => handleDocumentView(e.data),
      cellStyle: { cursor: "pointer", textDecoration: "underline" },
      cellClass: "text-truncate",
    },
    { field: "extension", headerName: "Type", flex: 1 },
    {
      field: "processStatus",
      headerName: "Verwerkt",
      flex: 2,
      cellRenderer: IsVectorizedComponent,
    },
    {
      field: "created",
      headerName: "Geupload",
      sort: "desc",
      valueFormatter: dateTimeFormatter,
      flex: 3,
    },
    {
      field: "actions",
      headerName: "Acties",
      flex: 2,
      cellRenderer: actionRenderer,
      wrapText: true,
      autoHeight: true,
    },
  ];

  const getDocumentsData = async () => {
    await Api.getPaginated("documents/").then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        const documentRowData: IUploadedDocumentRowData[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.forEach((documentData: any) => {
          documentRowData.push({
            id: documentData.id,
            name: `${documentData.title}.${documentData.extension}`,
            extension: documentData.extension,
            processStatus: documentData.process_status,
            created: isoStringToDate(documentData.created),
            url: documentData.file_download_url,
          });
        });
        setRowData(documentRowData);
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  React.useEffect(() => {
    setIsLoading(true);
    getDocumentsData().then(() => {
      hasAdminRights().then((_hasAdminRights) => {
        setEnableUpload(_hasAdminRights);
        // If the user is not an admin, hide the actions column.
        if (!_hasAdminRights) {
          gridRef.current?.api?.setColumnsVisible(["actions"], false);
        }
        setIsLoading(false);
      });
    });
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      updateDocumentStatuses().then(() => {});
    }, 1500);
    return () => clearInterval(interval);
  }, [rowData]);

  React.useEffect(() => {
    /**
     * Hide certain columns on smaller screens.
     */
    const columnsToHide: string[] = ["extension", "processStatus", "created"];
    if (isGridReady) {
      if (isScreenLg) {
        gridRef.current?.api?.setColumnsVisible(columnsToHide, true);
      } else {
        gridRef.current?.api?.setColumnsVisible(columnsToHide, false);
      }
    }
  }, [isGridReady, isScreenLg]);

  const updateDocumentStatuses = async () => {
    // First check the current statuses.
    // If they are all in a Done state, then we do not need to do anything.
    const isAllDone = rowData.every(
      (row) =>
        row.processStatus === "completed" || row.processStatus === "failed",
    );
    if (isAllDone) {
      return;
    }

    const response = await Api.get("/documents/statuses");
    if (_isApiError(response)) {
      setApiError(response);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const results: { [key: string]: any }[] = response as {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    }[];
    const statusData: IDocumentStatusData[] = results.map((result) => {
      return {
        id: result.id,
        processStatus: result.process_status,
      };
    });

    // Check if there are any that require changes.
    const currentData = rowData.map((row) => {
      return {
        id: row.id,
        processStatus: row.processStatus,
      };
    });

    const toUpdateData = statusData.filter((status) => {
      const currentStatus = currentData.find((data) => data.id === status.id);
      return currentStatus?.processStatus !== status.processStatus;
    });

    // If there is nothing to update we will not try to update the table.
    if (toUpdateData.length === 0) {
      return;
    }

    // Update the table with the new statuses.
    for (const item of toUpdateData) {
      const node = gridRef.current?.api.getRowNode(item.id);
      if (node?.data) {
        const newData = { ...node.data, processStatus: item.processStatus };
        node.setData(newData);
      }
    }
  };

  const NoRowsOverlayComponent = (props: { isLoading: boolean }) => {
    return (
      <div style={{ pointerEvents: "auto" }}>
        {props.isLoading ? (
          <Spinner animation="border" variant="secondary" />
        ) : (
          <p>Geen documenten.</p>
        )}
      </div>
    );
  };

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
        <h6 className={"m-0"}>Documenten</h6>
        {enableUpload ? (
          <Button
            title={"Nieuw document"}
            variant="success"
            type="button"
            onClick={() => navigate("/dashboard/documents/upload")}
            disabled={isLoading}
            className={
              "d-flex align-items-center position-absolute end-0 rounded-pill me-3"
            }
          >
            <PlusLg className={"me-2"} />
            Nieuw document
          </Button>
        ) : (
          <></>
        )}
      </div>

      {apiError ? (
        <ErrorAlert apiError={apiError} />
      ) : (
        <>
          <div className="ag-theme-quartz h-100" style={{ minHeight: "15rem" }}>
            <AgGridReact<IUploadedDocumentRowData>
              ref={gridRef}
              onGridReady={() => setIsGridReady(true)}
              // @ts-expect-error:next-line
              columnDefs={columnDefs}
              rowData={rowData}
              reactiveCustomComponents={true}
              rowClass={"ag-row-focused-on-top"}
              noRowsOverlayComponent={NoRowsOverlayComponent}
              noRowsOverlayComponentParams={{ isLoading: isLoading }}
              suppressCellFocus={true}
              getRowId={(params) => params.data.id}
              pagination={rowData.length > MAX_ITEMS_WITHOUT_PAGINATION}
            />
          </div>
        </>
      )}
    </div>
  );
}
