import React, { useRef } from "react";
import { Button, Card, Overlay, Popover, Spinner } from "react-bootstrap";
import {
  HandThumbsDown,
  HandThumbsDownFill,
  HandThumbsUp,
  HandThumbsUpFill,
} from "react-bootstrap-icons";
import parse from "html-react-parser";
import { NavigateFunction } from "react-router-dom";

import { dateToString } from "../../../../common/Utils";
import avatar from "../../../../../assets/images/avatar192.png";
import { getAssistantGreeting } from "../Utils";
import { Api } from "../../../../common/api/Api";
import { AnswerSpeechComponent } from "./AnswerSpeechComponent";
import { AnswerCopyComponent } from "./AnswerCopyComponent";
import {
  getDocumentBlobUrl,
  handleDownload,
} from "../../../../common/api/Utils";

const feedbackThumbsUp: string = "thumbs_up";
const feedbackThumbsDown: string = "thumbs_down";

const handleFeedback = (
  answer: IAnswer,
  feedback: string,
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>,
): void => {
  /**
   * Store given feedback.
   */
  // Update the answer message.
  answer.feedback = feedback;
  // Prepare the form data.
  const data: FormData = new FormData();
  data.append("feedback", feedback);
  // Make the request.
  Api.patch(`answers/${answer.id}/`, data).then(
    () => {
      // Pass.
    },
    (error: IApiError) => {
      setApiError(error);
    },
  );
};

const handleCitationView = (
  citation: ICitation,
  citationIndex: number,
  chatData: IChat,
  isWidget: boolean,
  navigate: NavigateFunction,
): void => {
  /**
   * View the citation's document's file.
   * On the widget, download the file.
   * On the full application, navigate to the citation detail screen.
   */
  if (isWidget) {
    getDocumentBlobUrl(citation.document).then((documentBlobUrl: string) => {
      return handleDownload(citation.document, documentBlobUrl);
    });
  } else {
    navigate("/dashboard/chats/chat/citation", {
      state: {
        citation: citation,
        citationIndex: citationIndex,
        chatTopic: chatData.topic,
      },
    });
  }
};

export const AnswerComponent = ({
  answer,
  playingMessage,
  setPlayingMessage,
  setApiError,
  isWidget,
  isScreenLg,
  organizationData,
  chatData,
  navigate,
}: {
  answer: IAnswer;
  playingMessage: IAnswer | null;
  setPlayingMessage: React.Dispatch<React.SetStateAction<IAnswer | null>>;
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>;
  isWidget: boolean;
  isScreenLg: boolean;
  organizationData: IOrganization | null;
  chatData: IChat;
  navigate: NavigateFunction;
}): React.JSX.Element => {
  const [_feedback, _setFeedback] = React.useState<string>(answer.feedback);
  const _handleFeedback = (feedback: string): void => {
    if (_feedback === feedback) {
      // Reset the feedback.
      feedback = "";
    }
    // Update the feedback locally.
    answer.feedback = feedback;
    _setFeedback(feedback);
    // Store the feedback.
    handleFeedback(answer, feedback, setApiError);
  };
  const [showAssistantOverlay, setShowAssistantOverlay] = React.useState(false);
  const assistantOverlayRef = useRef(null);

  return (
    <div className={"d-flex justify-content-start mb-3"}>
      {isScreenLg ? (
        <>
          <img
            alt="Avatar"
            title={"Avatar"}
            src={
              organizationData?.avatarUrl ? organizationData.avatarUrl : avatar
            }
            height="38"
            width="38"
            className={
              "overflow-hidden rounded-circle border border-1 border-light-subtle bg-transparent me-2"
            }
            style={{
              height: "38px",
              width: "38px",
              aspectRatio: 1,
              cursor: organizationData?.avatarUrl ? "pointer" : "auto",
            }}
            ref={assistantOverlayRef}
            onClick={() => {
              if (organizationData?.avatarUrl) {
                setShowAssistantOverlay(!showAssistantOverlay);
              }
            }}
          />
          <Overlay
            target={assistantOverlayRef.current}
            show={showAssistantOverlay}
            placement="right"
          >
            <Popover className={"rounded-3 border-light-subtle"}>
              <Popover.Body
                className={"d-flex align-content-center align-items-center"}
              >
                <div className={"flex-fill"}>
                  <img
                    alt="Avatar"
                    title={"Avatar"}
                    src={
                      organizationData?.avatarUrl
                        ? organizationData.avatarUrl
                        : avatar
                    }
                    height="96"
                    width="96"
                    className={
                      "overflow-hidden rounded-circle border border-2 border-light-subtle bg-transparent me-3"
                    }
                    style={{ height: "96px", width: "96px", aspectRatio: 1 }}
                  />
                </div>
                <span>{getAssistantGreeting(organizationData)}</span>
              </Popover.Body>
            </Popover>
          </Overlay>
        </>
      ) : (
        <></>
      )}
      <Card className={"w-75 bg-light rounded-3 border-1 border-light-subtle"}>
        <Card.Body>
          {answer.message === "" ? (
            <div
              className={
                "h-100 d-flex justify-content-center align-items-center"
              }
            >
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <div>
              <p className={"m-0"}>
                {parse(answer.message.replace(/\n/g, "<br/>"))}
              </p>
              {answer.citations && answer.citations.length > 0 ? (
                <>
                  <hr />
                  <small>
                    Citaties:
                    <ol className={"mb-0 overflow-hidden"}>
                      {answer.citations.map(
                        (citationData: ICitation, index: number) => {
                          const citationIndex: number = index + 1;
                          return (
                            <li key={`answer-${answer.id}-citation-${index}`}>
                              <span
                                className={
                                  "m-0 text-decoration-underline text-truncate"
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleCitationView(
                                    citationData,
                                    citationIndex,
                                    chatData,
                                    isWidget,
                                    navigate,
                                  )
                                }
                              >
                                {citationData.document.name}
                                {citationData.pageStart &&
                                citationData.pageEnd ? (
                                  <>
                                    {" "}
                                    {citationData.pageStart ===
                                    citationData.pageEnd ? (
                                      <>(pagina {citationData.pageStart})</>
                                    ) : (
                                      <>
                                        (pagina&apos;s {citationData.pageStart}-
                                        {citationData.pageEnd})
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </li>
                          );
                        },
                      )}
                    </ol>
                  </small>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </Card.Body>
        {answer.created ? (
          <Card.Footer
            className="d-flex flex-wrap align-items-center justify-content-between text-muted border-0 rounded-bottom-3"
            style={{ minHeight: "3rem" }}
          >
            {isScreenLg ? <small>{dateToString(answer.created)}</small> : <></>}
            <div
              className={
                isScreenLg
                  ? "d-flex justify-content-center"
                  : "w-100 d-flex justify-content-end"
              }
            >
              <div>
                <AnswerSpeechComponent
                  answer={answer}
                  playingMessage={playingMessage}
                  setPlayingMessage={setPlayingMessage}
                  setApiError={setApiError}
                />
                <AnswerCopyComponent answer={answer} />
                <Button
                  title={"Juist antwoord"}
                  size={"sm"}
                  variant="light"
                  type="button"
                  active={_feedback === feedbackThumbsUp}
                  onClick={() => _handleFeedback(feedbackThumbsUp)}
                  className={
                    "rounded-circle py-0 text-dark me-2 border-1 border-light-subtle"
                  }
                >
                  <span className={"d-flex align-items-center py-2"}>
                    {_feedback === feedbackThumbsUp ? (
                      <HandThumbsUpFill />
                    ) : (
                      <HandThumbsUp />
                    )}
                  </span>
                </Button>
                <Button
                  title={"Onjuist antwoord"}
                  size={"sm"}
                  variant="light"
                  type="button"
                  active={_feedback === feedbackThumbsDown}
                  onClick={() => _handleFeedback(feedbackThumbsDown)}
                  className={
                    "rounded-circle py-0 text-dark border-1 border-light-subtle"
                  }
                >
                  <span className={"d-flex align-items-center py-2"}>
                    {_feedback === feedbackThumbsDown ? (
                      <HandThumbsDownFill />
                    ) : (
                      <HandThumbsDown />
                    )}
                  </span>
                </Button>
              </div>
            </div>
          </Card.Footer>
        ) : (
          <></>
        )}
      </Card>
    </div>
  );
};
