interface IConfig {
  backendBaseUrl: string;
  version: string | null;
}

const config: IConfig = {
  backendBaseUrl:
    window._env.REACT_APP_BACKEND_BASE_URL || "http://localhost:8000",
  version: window._env.REACT_APP_VERSION || null,
};

export default config;
