import React, { useRef } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MicFill } from "react-bootstrap-icons";

import {
  blobToSpeech,
  getTextFromSpeech,
  handleMessageSendInner,
} from "../Utils";
import { AppDispatch } from "../../../../../store";

export const RecordComponent = ({
  setIsAnswering,
  setMessagesData,
  setMessage,
  chatData,
  setChatData,
  setApiError,
  dispatch,
}: {
  setIsAnswering: React.Dispatch<React.SetStateAction<boolean>>;
  setMessagesData: React.Dispatch<
    React.SetStateAction<Array<IQuestion | IAnswer>>
  >;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  chatData: IChat;
  setChatData: React.Dispatch<React.SetStateAction<IChat | null>>;
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>;
  dispatch: AppDispatch;
}): React.JSX.Element => {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const mediaBufferRef = useRef<Blob[]>([]);
  const [microphoneEnabled, setMicrophoneEnabled] =
    React.useState<boolean>(true);
  const [recording, setRecording] = React.useState<boolean>(false);
  const [showOverlayTrigger, setShowOverlayTrigger] =
    React.useState<boolean>(false);

  // keeping track of the start time of the recording
  // this allows us to give a warning if the user has not pressed it
  // long enough.
  const startTimeRef = useRef<number | null>(null);

  // Convenience method for stopping the recorder
  const stopRecorder = () => {
    if (mediaRecorderRef.current?.state === "recording") {
      mediaRecorderRef.current?.stop();
      mediaRecorderRef.current?.stream.getTracks().forEach((track) => {
        track.stop(); // we must stop then track before removing it
        mediaRecorderRef.current?.stream.removeTrack(track);
      });
    }
  };

  const onMouseDown = () => {
    startTimeRef.current = Date.now();
    setRecording(true);

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("Media devices not available");
      setMicrophoneEnabled(false);
      setRecording(false);
      return;
    }

    // Stop all microphone tracks. We need to do this, because the first time the user
    // lands on this page, the microphone access is asked. When that succeeds, we start recording.
    // But we can not stop recording the normal way, because the mouseUp event not triggered.
    // So we need to stop all tracks, and start recording again.
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
          stream.removeTrack(track);
        });
        stopRecorder();
      })
      .catch(() => {
        // this only happens if user actively denies microphone access.
        setMicrophoneEnabled(false);
        setRecording(false);
        return;
      });

    const audioMimeTypes = [
      "audio/mpeg", // mp3
      "audio/wav", // wav
      "audio/flac", // flac
      "audio/aac", // aac
      "audio/mp4", // mp4
      "audio/mp4;codecs=mp4a.40.2",
      "audio/mp4;codecs=opus",
      "audio/webm;codecs=opus",
      "audio/webm;codecs=vorbis",
      "audio/ogg;codecs=opus",
      "audio/ogg;codecs=vorbis",
    ];

    const supportedAudioMimeTypes = audioMimeTypes.filter((mimeType) =>
      MediaRecorder.isTypeSupported(mimeType),
    );
    if (supportedAudioMimeTypes.length === 0) {
      console.error("No supported audio MIME types found");
      setMicrophoneEnabled(false);
      setRecording(false);
      return;
    }

    const mimeType = supportedAudioMimeTypes[0];
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaBufferRef.current = [];
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: mimeType,
        });
        mediaRecorderRef.current.start(100);
        mediaRecorderRef.current.ondataavailable = (e) => {
          console.log("data available", e.data);
          mediaBufferRef.current.push(e.data);
        };
      })
      .catch((e) => {
        console.error(e);
        setMicrophoneEnabled(false);
        setRecording(false);
        return;
      });
  };

  const onMouseUp = async () => {
    if (!mediaRecorderRef.current || !startTimeRef.current) {
      console.log("no media recorder or start time");
      return;
    }

    setRecording(false);

    // fire ondataavailable one last time
    if (mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.requestData();
      // stop the recording, and stop all tracks;
      stopRecorder();
    } else {
      return;
    }

    const endTime = Date.now();
    const duration = endTime - startTimeRef.current;
    if (duration < 2000) {
      // Show a warning if the user has not pressed the button long enough (less than 2s).
      setShowOverlayTrigger(true);
      // Hide the warning after 3 seconds.
      setTimeout(() => {
        setShowOverlayTrigger(false);
      }, 3000);
    } else {
      setIsAnswering(true);
      const blob = new Blob(mediaBufferRef.current, {
        type: mediaRecorderRef.current.mimeType,
      });

      const speech = await blobToSpeech(blob);
      console.log("blob", blob);
      console.log("blob size", blob.size);

      const text = await getTextFromSpeech(speech);
      if (text && text.length > 0) {
        setMessage(text);
        await handleMessageSendInner(
          text,
          setIsAnswering,
          setMessagesData,
          setMessage,
          chatData,
          setChatData,
          setApiError,
          dispatch,
        );
      }
      setIsAnswering(false);
    }

    // set the media recorder back to null
    mediaRecorderRef.current = null;
    mediaBufferRef.current = [];
  };
  return (
    <OverlayTrigger
      show={showOverlayTrigger}
      placement={"left"}
      overlay={
        <Tooltip id={"tooltip-record-button"} className={"me-2"}>
          <div className={"py-1"}>
            Houd ten minste 2 seconden ingedrukt om op te nemen.
          </div>
        </Tooltip>
      }
    >
      <div className={"ms-3 rounded-pill border border-1 border-light-subtle"}>
        <Button
          title={"Opnemen"}
          variant={recording ? "danger" : "secondary"}
          type={"button"}
          active={recording}
          onMouseDown={onMouseDown}
          onTouchStart={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseUp}
          onTouchEnd={onMouseUp}
          onTouchCancel={onMouseUp}
          disabled={!microphoneEnabled}
          className={"h-100 rounded-pill border-5 border-light"}
        >
          <span className={"d-flex align-items-center"}>
            <MicFill />
          </span>
        </Button>
      </div>
    </OverlayTrigger>
  );
};
