import React from "react";
import { NavigateFunction } from "react-router-dom";

import { clearChatMessages, createNewChat } from "../Utils";
import { AppDispatch } from "../../../../../store";

const handleChatNew = (
  setChatData: React.Dispatch<React.SetStateAction<IChat | null>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setMessagesData: React.Dispatch<
    React.SetStateAction<Array<IQuestion | IAnswer>>
  >,
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>,
  setMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsAnswering: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
): void => {
  /**
   * Start a new chat.
   */
  // Show loading indicator.
  setIsLoading(true);
  // Reset the state.
  clearChatMessages(
    setChatData,
    setMessagesData,
    setApiError,
    setMessage,
    setIsAnswering,
  );
  // Create a new chat.
  createNewChat(setApiError, dispatch).then(
    (chat: ISerializableChat | null) => {
      if (chat) {
        // View the newly created chat.
        navigate("/dashboard/chats/chat", { state: { chat: chat } });
      }
      setIsLoading(false);
    },
  );
};

export const NewChatComponent = ({
  isLoading,
  isAnswering,
  setChatData,
  setMessagesData,
  setApiError,
  setMessage,
  setIsLoading,
  setIsAnswering,
  dispatch,
  navigate,
}: {
  isLoading: boolean;
  isAnswering: boolean;
  setChatData: React.Dispatch<React.SetStateAction<IChat | null>>;
  setMessagesData: React.Dispatch<
    React.SetStateAction<Array<IQuestion | IAnswer>>
  >;
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAnswering: React.Dispatch<React.SetStateAction<boolean>>;
  dispatch: AppDispatch;
  navigate: NavigateFunction;
}): React.JSX.Element => {
  return (
    <div className={"text-center"} style={{ marginTop: "-0.75rem" }}>
      <span
        className={
          "m-0 p-0 text-decoration-underline text-truncate text-dark small"
        }
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (isLoading || isAnswering) {
            return undefined;
          } else {
            return handleChatNew(
              setChatData,
              setIsLoading,
              setMessagesData,
              setApiError,
              setMessage,
              setIsAnswering,
              dispatch,
              navigate,
            );
          }
        }}
        title={"Begin een nieuwe chat"}
      >
        Of begin een nieuwe chat
      </span>
    </div>
  );
};
