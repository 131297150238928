import React from "react";
import { Link } from "react-router-dom";

import { Api } from "../../common/api/Api";
import { deserializeChat } from "../../common/Utils";
import ErrorAlert from "../../common/ErrorAlert";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { setChats } from "../../../features/recentChatsSlice";

const topicFormatter = (
  s: string | undefined,
  maxChars: number | null = null,
): string => {
  if (!s) {
    return "Geen onderwerp";
  } else {
    if (maxChars === null) {
      return s;
    }
    if (s.length > maxChars) {
      return s.substring(0, maxChars) + "...";
    }
    return s;
  }
};

const ChatItem = (props: { chat: IChat }): React.JSX.Element => {
  return (
    <Link
      to={`/dashboard/chats/chat`}
      title={topicFormatter(props.chat.topic)}
      state={{ chat: props.chat }}
      className={"mb-2 link-underline link-underline-opacity-0"}
    >
      <div className={"px-2 rounded-3 nav-link py-1"}>
        <div className={"d-flex align-items-center justify-content-start"}>
          <span className={"small text-truncate"}>
            {topicFormatter(props.chat.topic)}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default function MostRecentChats(): React.JSX.Element {
  const rowData = useAppSelector((state) => state.recentChats.value);
  const dispatch = useAppDispatch();

  const [apiError, setApiError] = React.useState<IApiError | undefined>();

  const getChatsData = async () => {
    await Api.get("chats/most_recent").then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        const rows: ISerializableChat[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.results.forEach((chatData: any) => {
          const row: ISerializableChat = {
            id: chatData.id,
            url: chatData.url,
            topic: chatData.topic,
            created: chatData.created,
            modified: chatData.modified,
          };
          rows.push(row);
        });
        dispatch(setChats(rows));
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  React.useEffect(() => {
    getChatsData().then(() => {});
  }, []);

  // Show nothing if there are no chats yet, not even the header.
  if (rowData.length === 0) {
    return <></>;
  }

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div className={"d-flex align-items-center my-2 py-1 text-muted"}>
        <p className={"m-0 small text-nowrap overflow-hidden"}>
          Meest recente chats
        </p>
      </div>

      {apiError ? (
        <ErrorAlert apiError={apiError} />
      ) : (
        <>
          {rowData.map((chat: ISerializableChat) => (
            <ChatItem key={chat.id} chat={deserializeChat(chat)} />
          ))}
        </>
      )}
    </div>
  );
}
