import { configureStore } from "@reduxjs/toolkit";
import recentChatsReducer from "./features/recentChatsSlice";
import isScreenLgReducer from "./features/isScreenLgSlice";
import userReducer from "./features/userSlice";
import organizationReducer from "./features/organizationSlice";
import organizationApiKeyReducer from "./features/organizationApiKeySlice";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: {
    recentChats: recentChatsReducer,
    isScreenLg: isScreenLgReducer,
    user: userReducer,
    organization: organizationReducer,
    organizationApiKey: organizationApiKeyReducer,
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
