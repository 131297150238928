import { parseISO, formatISO } from "date-fns";
import { format as dateFnsFormat } from "date-fns";
import { nl, enUS } from "date-fns/locale";

export const isoStringToDate = (isoString: string): Date => {
  return parseISO(isoString);
};

type SupportedLocale = "NL" | "enUS";
export const dateToString = (
  date: Date,
  locale: SupportedLocale = "NL",
): string => {
  if (locale === "NL") {
    return dateFnsFormat(date, "dd-MM-yyyy 'om' HH:mm", { locale: nl });
  } else if (locale === "enUS") {
    return dateFnsFormat(date, "Pp", { locale: enUS });
  }
  return "";
};

export const truncateString = (
  fullStr: string,
  truncateAfterCharCount: number = 50,
): string => {
  if (fullStr.length <= truncateAfterCharCount) {
    return fullStr;
  } else {
    return fullStr.substring(0, truncateAfterCharCount) + "\u2026";
  }
};

export const getFileHash = async (file: File): Promise<string> => {
  /**
   * Calculate the SHA-256 hash of a file,
   * using the Web Crypto API's digest method
   * (https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest).
   */
  const hash: ArrayBuffer = await file.arrayBuffer();
  const hashBuffer: ArrayBuffer = await crypto.subtle.digest("SHA-256", hash);
  const hashArray: Uint8Array = new Uint8Array(hashBuffer);
  const hashHex: string = Array.from(hashArray)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

export const serializeChat = (chat: IChat): ISerializableChat => {
  return {
    id: chat.id,
    url: chat.url,
    topic: chat.topic,
    created: formatISO(chat.created),
    modified: formatISO(chat.modified),
  };
};

export const deserializeChat = (chat: ISerializableChat): IChat => {
  return {
    id: chat.id,
    url: chat.url,
    topic: chat.topic,
    created: parseISO(chat.created),
    modified: parseISO(chat.modified),
  };
};
