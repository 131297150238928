import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OrganizationState {
  value: IOrganization | null;
}

const defaultState: OrganizationState = {
  value: null,
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState: defaultState,
  reducers: {
    setOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.value = action.payload;
    },
    removeOrganization: (state) => {
      state.value = null;
    },
  },
});

export const { setOrganization, removeOrganization } =
  organizationSlice.actions;
export default organizationSlice.reducer;
