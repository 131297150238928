import React, { useRef } from "react";
import { Button, Spinner } from "react-bootstrap";
import { VolumeUpFill, StopCircleFill } from "react-bootstrap-icons";

import { isApiError } from "../Utils";
import { Api } from "../../../../common/api/Api";

export const AnswerSpeechComponent = ({
  answer,
  playingMessage,
  setPlayingMessage,
  setApiError,
}: {
  answer: IAnswer;
  playingMessage: IAnswer | null;
  setPlayingMessage: React.Dispatch<React.SetStateAction<IAnswer | null>>;
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>;
}): React.JSX.Element => {
  // Keep a ref around because changes to this thing should not cause a re-render.
  const audioElementRef = useRef<HTMLAudioElement | null>(null);
  const [isAudioLoading, setAudioIsLoading] = React.useState<boolean>(false);

  const handlePlay = async () => {
    setAudioIsLoading(true);
    setPlayingMessage(answer);
    const speechItem = await Api.get(`answers/${answer.id}/speech/`);
    if (isApiError(speechItem)) {
      setApiError(speechItem);
      return;
    }
    const base64 = speechItem.speech.data;
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const audioBlob = new Blob([bytes.buffer], {
      type: `audio/${speechItem.speech.encoding}`,
    });
    const audioUrl = URL.createObjectURL(audioBlob);
    try {
      // In a try because media stream may be corrupted and whatnot
      audioElementRef.current = new Audio(audioUrl);
      // Set the onended event to reset the playing state
      audioElementRef.current.onended = () => {
        setPlayingMessage(null);
      };
      await audioElementRef.current.play();
    } catch (e) {
      console.error(e);
    }
    setAudioIsLoading(false);
  };

  const handleStop = () => {
    audioElementRef.current?.pause();
    setPlayingMessage(null);
  };

  const getIcon = () => {
    const currentMessageIsOwn = playingMessage?.id === answer.id;
    if (!currentMessageIsOwn) {
      return <VolumeUpFill />;
    }
    if (isAudioLoading) {
      return (
        <Spinner size={"sm"} style={{ maxWidth: "14px", maxHeight: "14px" }} />
      );
    }
    return <StopCircleFill />;
  };

  return (
    <Button
      title={playingMessage?.id === answer.id ? "Stop" : "Speel af"}
      size={"sm"}
      variant={"light"}
      type={"button"}
      className={
        "rounded-circle py-0 text-dark me-2 border-1 border-light-subtle"
      }
      onClick={playingMessage?.id === answer.id ? handleStop : handlePlay}
      disabled={
        isAudioLoading ||
        (playingMessage !== null && playingMessage.id !== answer.id)
      }
    >
      <span className={"d-flex align-items-center py-2"}>{getIcon()}</span>
    </Button>
  );
};
