import "react-pdf/dist/Page/AnnotationLayer.css";

import React from "react";
import { Pagination } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { useAppSelector } from "../../hooks";

// Configure PDF.js worker (https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#configure-pdfjs-worker).
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

export const DocumentViewer = (props: {
  document: IDocument;
  documentBlobUrl: string;
  documentPageIndex: number;
}): React.JSX.Element => {
  /**
   * Document viewer component.
   * On large displays, show browser's default PDF viewer.
   * On small displays, show a custom (paginated) PDF viewer.
   */
  const document = props.document;
  const documentBlobUrl = props.documentBlobUrl;

  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [documentCurrentPage, setDocumentCurrentPage] = React.useState<number>(
    props.documentPageIndex,
  );
  const [documentLastPage, setDocumentLastPage] = React.useState<number>(1);
  const [documentWidth, setDocumentWidth] = React.useState<number>(0);
  const [documentHeight, setDocumentHeight] = React.useState<number>(0);
  const [documentScale, setDocumentScale] = React.useState<number>(1);

  const handleDocumentContainerRef = (ref: HTMLDivElement | null) => {
    /**
     * Dynamically set the document width/height
     * based on the available container width/height
     * and if the page is being viewed in portrait or landscape orientation.
     * Also set the document scale to fit the document in the container.
     */
    if (ref && !documentWidth && !documentHeight) {
      const borders: number = 2;
      // A4 ratio is 1:√2.
      const a4Width: number = 1;
      const a4Height: number = Math.sqrt(2);
      // Get the document container width/height.
      const documentContainerWidth: number = ref.offsetWidth - borders;
      const documentContainerHeight: number = ref.offsetHeight - borders;
      // Set the document width/height.
      if (documentContainerHeight > documentContainerWidth) {
        // Portrait orientation; set width to 100%.
        setDocumentWidth(documentContainerWidth);
        const potentialDocumentHeight: number =
          (documentContainerWidth * a4Height) / a4Width;
        // If the potential document height is larger than the container height,
        // set the document scale to fit the document in the container.
        if (potentialDocumentHeight > documentContainerHeight) {
          // Calculate the document scale, based on the A4 ratio.
          setDocumentScale(documentContainerHeight / potentialDocumentHeight);
        }
      } else {
        // Landscape orientation; set height to 100%.
        setDocumentHeight(documentContainerHeight);
        const potentialDocumentWidth: number =
          (documentContainerHeight * a4Width) / a4Height;
        // If the potential document width is larger than the container width,
        // set the document scale to fit the document in the container.
        if (potentialDocumentWidth > documentContainerWidth) {
          // Calculate the document scale, based on the A4 ratio.
          setDocumentScale(documentContainerWidth / potentialDocumentWidth);
        }
      }
    }
  };

  if (isScreenLg) {
    return (
      // On large displays, show browser's default PDF viewer.
      <div
        className="d-flex w-100 h-100 justify-content-center align-items-center bg-light rounded-3 border border-1 border-light-subtle z-1 position-relative"
        style={{ minHeight: "15rem" }}
      >
        <div
          className={
            "d-flex w-100 h-100 justify-content-center align-items-center mt-3 z-2 position-absolute"
          }
        >
          <p className={"text-truncate mx-3"}>{document.name}</p>
        </div>
        {["pdf", "txt"].includes(document.extension) ? (
          <embed
            className={"rounded-3 position-absolute z-3"}
            src={`${documentBlobUrl}#page=${documentCurrentPage}`}
            width="100%"
            height="100%"
          />
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return (
      // On small displays, show a custom (paginated) PDF viewer.
      <div
        ref={handleDocumentContainerRef}
        className="d-flex w-100 h-100 justify-content-center align-items-center bg-light rounded-3 border border-1 border-light-subtle"
        style={{ minHeight: "15rem" }}
      >
        {["pdf"].includes(document.extension) ? (
          <div
            className={
              "d-flex h-100 w-100 flex-column justify-content-center align-items-center text-light rounded-3"
            }
          >
            <Document
              file={documentBlobUrl}
              onLoadSuccess={(pdf) => setDocumentLastPage(pdf.numPages)}
              className={
                "h-100 d-flex flex-fill justify-content-center align-items-center"
              }
            >
              <Page
                pageNumber={documentCurrentPage}
                renderTextLayer={false}
                width={documentWidth ? documentWidth : undefined}
                height={documentHeight ? documentHeight : undefined}
                scale={documentScale ? documentScale : undefined}
                className={"rounded-3"}
                devicePixelRatio={window.devicePixelRatio ?? 1}
              />
            </Document>
            <Pagination className={"mt-3"}>
              <Pagination.First
                linkClassName={"text-dark"}
                disabled={documentCurrentPage === 1}
                onClick={() => setDocumentCurrentPage(1)}
              />
              <Pagination.Prev
                linkClassName={"text-dark"}
                disabled={documentCurrentPage === 1}
                onClick={() => setDocumentCurrentPage(documentCurrentPage - 1)}
              />
              <Pagination.Item active={true} disabled={true}>
                Pagina {documentCurrentPage} van {documentLastPage}
              </Pagination.Item>
              <Pagination.Next
                linkClassName={"text-dark"}
                disabled={documentCurrentPage === documentLastPage}
                onClick={() => setDocumentCurrentPage(documentCurrentPage + 1)}
              />
              <Pagination.Last
                linkClassName={"text-dark"}
                disabled={documentCurrentPage === documentLastPage}
                onClick={() => setDocumentCurrentPage(documentLastPage)}
              />
            </Pagination>
          </div>
        ) : (
          <p className={"text-wrap mx-3"}>{document.name}</p>
        )}
      </div>
    );
  }
};
