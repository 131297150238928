import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  value: IUser | null;
}

const defaultState: UserState = {
  value: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: defaultState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.value = action.payload;
    },
    removeUser: (state) => {
      state.value = null;
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
