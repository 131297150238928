import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Copy } from "react-bootstrap-icons";

import { handleCopyAnswer } from "../Utils";

export const AnswerCopyComponent = ({
  answer,
}: {
  answer: IAnswer;
}): React.JSX.Element => {
  const [show, setShow] = React.useState<boolean>(false);
  return (
    <OverlayTrigger
      show={show}
      trigger={"click"}
      placement={"top"}
      overlay={
        <Tooltip id={"tooltip-copy-answer"} className={"mb-3"}>
          <div className={"py-1"}>
            Antwoord is gekopiëerd naar het klembord.
          </div>
        </Tooltip>
      }
    >
      <Button
        title={"Kopieer antwoord"}
        size={"sm"}
        variant="light"
        type="button"
        onClick={() => {
          handleCopyAnswer(answer);
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 1000);
        }}
        className={
          "rounded-circle py-0 text-dark me-4 border-1 border-light-subtle"
        }
      >
        {" "}
        <span className={"d-flex align-items-center py-2"}>
          <Copy />
        </span>
      </Button>
    </OverlayTrigger>
  );
};
