import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OrganizationApiKeyState {
  value: string | null;
}

const defaultState: OrganizationApiKeyState = {
  value: null,
};

export const organizationApiKeySlice = createSlice({
  name: "organizationApiKey",
  initialState: defaultState,
  reducers: {
    setOrganizationApiKey: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    removeOrganizationApiKey: (state) => {
      state.value = null;
    },
  },
});

export const { setOrganizationApiKey, removeOrganizationApiKey } =
  organizationApiKeySlice.actions;
export default organizationApiKeySlice.reducer;
