import "react-pdf/dist/Page/AnnotationLayer.css";

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { ArrowLeft, CloudArrowDown } from "react-bootstrap-icons";

import { handleDownload, getDocumentBlobUrl } from "../../common/api/Utils";
import { DocumentViewer } from "../../common/DocumentViewer";

export default function DocumentsDetail(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const documentData: IUploadedDocumentRowData = location?.state?.document;
  const [documentBlobUrl, setDocumentBlobUrl] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setIsLoading(true);
    if (!documentData?.url) {
      // If no document data, redirect to documents overview
      // (this is probably caused by a user copy-pasting the URL).
      return navigate("/dashboard/documents");
    } else {
      getDocumentBlobUrl(documentData).then((documentBlobUrl: string) => {
        setDocumentBlobUrl(documentBlobUrl);
        setIsLoading(false);
      });
    }
  }, [navigate, documentData]);

  return (
    <div className={"h-100 d-flex flex-column"}>
      {isLoading ? (
        <div
          className={"h-100 d-flex justify-content-center align-items-center"}
        >
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
            <Button
              title={"Vorige"}
              name={"Vorige"}
              type="button"
              onClick={() => navigate("/dashboard/documents")}
              className={"d-flex me-2 border-0 bg-transparent text-muted p-0"}
            >
              <ArrowLeft />
            </Button>
            <h6
              onClick={() => navigate("/dashboard/documents")}
              className={"m-0 ms-1 text-decoration-underline"}
              style={{ cursor: "pointer" }}
            >
              Documenten
            </h6>
            <h6 className={"m-0 mx-2"}>/</h6>
            <h6 className={"m-0 text-truncate"}>{documentData.name}</h6>
          </div>

          <DocumentViewer
            document={documentData}
            documentBlobUrl={documentBlobUrl}
            documentPageIndex={1}
          />

          <div className={"d-flex justify-content-end mt-3"}>
            <Button
              title={"Document downloaden"}
              variant="dark"
              type="button"
              onClick={() => handleDownload(documentData, documentBlobUrl)}
              className={"d-flex align-items-center rounded-3"}
            >
              <CloudArrowDown className={"me-2"} />
              Document downloaden
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
