import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import config from "./config";

Sentry.init({
  // Disable on localhost, we're probably just dev there....
  enabled: !config.backendBaseUrl.includes("localhost"),
  environment: config.backendBaseUrl.includes("belle")
    ? "belle"
    : config.backendBaseUrl.includes("dev")
      ? "dev"
      : "prod",
  release: config.version || undefined,
  dsn: "https://a7505c1f786ea5596a461f454900459a@o4508206279163904.ingest.de.sentry.io/4508438778740816",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    /^https:\/\/api\.manual\.app/,
    new RegExp(config.backendBaseUrl),
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
