import React from "react";
import { Button } from "react-bootstrap";
import { NavigateFunction } from "react-router-dom";
import { ArrowLeft } from "react-bootstrap-icons";

import { truncateString } from "../../../../common/Utils";

export const BreadcrumbComponent = ({
  topic,
  navigate,
}: {
  topic: string | null;
  navigate: NavigateFunction;
}): React.JSX.Element => {
  return (
    <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
      <Button
        title={"Vorige"}
        name={"Vorige"}
        type="button"
        onClick={() => navigate("/dashboard/chats")}
        className={"d-flex me-2 border-0 bg-transparent text-muted p-0"}
      >
        <ArrowLeft />
      </Button>
      <h6
        onClick={() => navigate("/dashboard/chats")}
        className={"m-0 ms-1 text-decoration-underline"}
        style={{ cursor: "pointer" }}
      >
        Chats
      </h6>
      <h6 className={"m-0 mx-2"}>/</h6>
      <h6 className={"m-0 text-truncate"}>
        Chat
        {topic ? (
          <span>
            : {` `}
            <i>{truncateString(topic)}</i>
          </span>
        ) : (
          ""
        )}
      </h6>
    </div>
  );
};
